import React from 'react'
import { Form } from 'react-bootstrap'
import CreatableSelect from 'react-select/creatable'
import styles from '../../page.module.scss'
import { Controller } from 'react-hook-form'

const customStyles = {
	option: (base, { data, isDisabled, isFocused, isSelected, menuPortal }) => {
		return {
			...base,
			backgroundColor: isFocused && 'rgba(234, 49, 98, 0.14)',
			color: 'black',
		}
	},
	menuList: (provided) => ({ ...provided, padding: 0, height: '100%', maxHeight: '8rem', minHeight: '2rem', fontSize: '12px' }),
	control: (baseStyles, state) => ({
		...baseStyles,
		minHeight: '2rem',
		fontSize: '12px',
	}),
	dropdownIndicator: (base) => ({
		...base,
		padding: 0,
	}),
	clearIndicator: (base) => ({
		...base,
		padding: 0,
	}),
}

const SelectElemet = ({ id, label = null, name = null, rules, options, placeholder, errors, control, ...props }) => {
	return (
		<Form.Group>
			{label && <Form.Label className='speck-form-label'>{label}</Form.Label>}
			<div className='position-relative d-flex'>
				<Controller
					name={name}
					rules={rules}
					control={control}
					render={({ field }) => (
						<div className='w-100'>
							<CreatableSelect {...field} isClearable styles={customStyles} placeholder={placeholder} options={options} {...props} />
							{errors && <Form.Text className={`${styles.spanError} text-danger card-span`}>{errors}</Form.Text>}
						</div>
					)}
				/>
			</div>
		</Form.Group>
	)
}

export default SelectElemet
