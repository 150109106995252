import API from "@/data/api";
import postUrl from "./paths";

export const createLead = (leadDto) =>
  new Promise((resolve, reject) => {
    API.post(postUrl.createLead, { ...leadDto, source: 'WEBSITE' })
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })