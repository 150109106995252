'use client'

import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { usePathname } from 'next/navigation'
import styles from '../page.module.scss'
import { Button, Form, InputGroup, Spinner, Stack, Tab, Col, Row } from 'react-bootstrap'
import { IoCloseSharp } from 'react-icons/io5'
import { MdOutlineEmail, MdProductionQuantityLimits, MdSupportAgent } from 'react-icons/md'
import { TbMapPinCode } from 'react-icons/tb'
import Buyer from '@/assets/home/buyer.png'
import Vendor from '@/assets/home/vendor.png'
import Image from 'next/image'
import CountryCodes from '@/constants/CountryCodes'
import { FiEdit } from 'react-icons/fi'
import { PiBuildingOffice } from 'react-icons/pi'
import { createLead } from '@/data/conn/post/order'
import Input from '@/components/base/FormElements/Input'
import SelectElemet from '@/components/base/FormElements/Select'
import { ReactGAEvent } from '@/components/ReactGAs'

const initialState = {
	product: '',
	quantity: '',
	company: '',
	pincode: '',
	pan: '',
	email: '',
	country_code: { value: 'IN', label: '+91' },
	mobile_number: '',
	check: true,
}

const StickyForm = ({ categoryData }) => {
	const pathname = usePathname()

	const [formState, setFormState] = useState(initialState)
	const [isFormOpen, setIsFormOpen] = useState(false)
	const [isBuy, setIsBuy] = useState(true)
	const [categories, setCategories] = useState(categoryData || [])
	const [loader, setLoader] = useState(false)

	const {
		control,
		register,
		handleSubmit,
		watch,
		reset,
		formState: { errors, isValid },
		getValues,
	} = useForm({
		mode: 'onTouched',
		reValidateMode: 'onChange',
		defaultValues: formState,
		invalidOption: true,
	})
	const values = getValues()

	useEffect(() => {
		setTimeout(() => {
			setIsFormOpen(true)
		}, 10000)
	}, [])

	useEffect(() => {
		const subscription = watch((value, { key, type }) => {})
		return () => subscription.unsubscribe()
	}, [watch])

	const submitHandler = (values) => {
		setLoader(true)
		const lead = {
			category: values?.product?.label,
			weight: values?.quantity,
			organisation_name: values?.company,
			pan: values?.pan?.toUpperCase() ?? null,
			email: values?.email,
			mobile_number: values?.mobile_number,
			country_code: values?.country_code?.value,
			location: values?.pincode ?? null,
			lead_type: 'form',
		}
		createLead(lead)
			.then((res) => {
				reset()
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				setLoader(false)
			})
	}

	const closeButtonHandler = () => {
		ReactGAEvent({ action: 'Get Quote', event: 'Form Submit', label: 'Get Quote', category: 'Quote Request' })
		setIsFormOpen(!isFormOpen)
	}

	return (
		<>
			<div className={`${styles.stickyForm} ${pathname.includes('products') ? 'invisible' : ''}`}>
				{isFormOpen && (
					<div className={styles.openForm}>
						<div className={styles.mainForm}>
							<Tab.Container id='left-tabs-example' defaultActiveKey='first'>
								<Row>
									<Col key={`inline-radio`} className={`${styles.formNavBar} d-flex align-items-center justify-content-center`}>
										<Stack direction='horizontal' gap={3}>
											<Button
												variant={'outline-secondary'}
												size='sm'
												className={`${styles.selectedTab} ${isBuy && styles.borderColor}`}
												onClick={() => {
													setIsBuy(true)
													reset()
												}}
											>
												<Image
													src={Buyer}
													width={20}
													height={20}
													alt='..'
													className={!isBuy ? styles.unSelectedIcon : null}
												/>
												<span>Buy</span>
											</Button>
											<Button
												variant={'outline-secondary'}
												size='sm'
												className={`${styles.selectedTab} ${!isBuy && styles.borderColor}`}
												onClick={() => {
													setIsBuy(false)
													reset()
												}}
											>
												<Image
													src={Vendor}
													width={20}
													height={20}
													alt='..'
													className={isBuy ? styles.unSelectedIcon : null}
												/>
												<span>Sell</span>
											</Button>
										</Stack>
									</Col>
								</Row>

								<Form onSubmit={handleSubmit(submitHandler)}>
									<Row className='mb-2'>
										<SelectElemet
											label={'Product'}
											name='product'
											control={control}
											rules={{ required: 'Please select product' }}
											placeholder='Select Product'
											options={categories?.map((guest, index) => {
												return {
													label: guest?.category_name,
													value: guest?.category_id,
													key: index,
												}
											})}
											errors={errors?.product?.message}
										/>
									</Row>
									<Row className='mb-2'>
										<Input
											id='quantity'
											label='Quantity'
											name='quantity'
											control={control}
											labelIcon={<MdProductionQuantityLimits />}
											type='number'
											placeholder='Enter Quantity'
											cssClasses={`rounded-0 rounded-end shadow-none`}
											rules={{ required: 'Please enter quantity' }}
											errors={errors?.quantity?.message}
											value={values?.quantity}
										/>
									</Row>
									<Row className='mb-2'>
										<Input
											id='company'
											label='Company'
											name='company'
											control={control}
											labelIcon={<PiBuildingOffice />}
											type='text'
											placeholder='Your Company'
											cssClasses={`rounded-0 rounded-end shadow-none`}
											rules={{ required: 'Please enter company' }}
											errors={errors?.company?.message}
											value={values?.company}
										/>
									</Row>
									<Row className='mb-2'>
										{isBuy ? (
											<Input
												id='pincode'
												label='Pincode'
												name='pincode'
												control={control}
												labelIcon={<TbMapPinCode />}
												type='number'
												placeholder='Enter Pincode'
												cssClasses={`rounded-0 rounded-end shadow-none`}
												rules={{
													required: 'Please enter pincode',
													maxLength: { value: 6, message: 'Please enter a pincode.' },
													minLength: { value: 6, message: 'Please enter a pincode.' },
												}}
												errors={errors?.pincode?.message}
												value={values?.pincode}
											/>
										) : (
											<Input
												id='pan-1'
												label='PAN Number'
												name='pan'
												control={control}
												labelIcon={<FiEdit />}
												type='text'
												placeholder='AAAAA9999A'
												cssClasses={`rounded-0 rounded-end shadow-none text-uppercase`}
												rules={{
													pattern: {
														value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
														message: 'Please enter a valid PAN',
													},
												}}
												errors={errors?.pan?.message}
												value={values?.pan}
											/>
										)}
									</Row>
									<Row className='mb-2'>
										<Input
											id='email'
											label='Email'
											name='email'
											control={control}
											labelIcon={<MdOutlineEmail />}
											type='email'
											placeholder='Your Email '
											cssClasses={`${styles.speakInput} rounded-0 rounded-end shadow-none`}
											rules={{
												required: 'Email is required',
												pattern: {
													value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
													message: 'Please enter a valid email',
												},
											}}
											errors={errors?.email?.message}
											value={values?.email}
										/>
									</Row>
									<Row className='mb-2'>
										<Form.Group>
											<Form.Label className='speck-form-label'>Mobile Number</Form.Label>
											<InputGroup className='flex-nowrap'>
												<div className='container'>
													<div className='row'>
														<div className='col-3 p-0'>
															<SelectElemet
																name='country_code'
																control={control}
																isClearable={false}
																rules={{ required: 'Select one country code' }}
																options={CountryCodes?.map((guest, index) => {
																	return {
																		label: guest?.dial_code,
																		value: guest?.code,
																		key: index,
																	}
																})}
																// components={{ DropdownIndicator: null }}
																errors={errors?.country_code?.message}
																isValidNewOption={() => false}
															/>
														</div>
														<div className='col-9 px-1'>
															<div className='position-relative w-100'>
																<Input
																	id='mobile_number'
																	name='mobile_number'
																	type='number'
																	placeholder='999XXXXX98'
																	className={`${styles.speakInput} ms-1 rounded shadow-none`}
																	control={control}
																	rules={{
																		required: 'Please enter Phone no.',
																		maxLength: { value: 15, message: 'Please enter a valid no.' },
																		minLength: { value: 6, message: 'Please enter a valid no.' },
																		pattern: { value: /^[0-9]+$/, message: 'Enter a valid Phone No.' },
																		message: 'Enter valid phone number',
																	}}
																	errors={errors?.mobile_number?.message}
																	value={values?.mobile_number}
																/>
															</div>
														</div>
													</div>
												</div>
											</InputGroup>
										</Form.Group>
									</Row>
									<Row className='mt-3 mx-1'>
										<Button variant='primary' className='border-0' type='submit' disabled={!isValid || loader}>
											Submit
										</Button>
									</Row>
									{loader && (
										<div className='d-flex justify-content-center align-items-center'>
											<div className={styles.LoaderWrapper}>
												<div className={styles.LoaderBackground}>
													<Spinner
														animation='border'
														variant='danger'
														className={`position-absolute ${styles.spinnerBootom}`}
													/>
												</div>
											</div>
										</div>
									)}
								</Form>
							</Tab.Container>
						</div>
					</div>
				)}
				{!isFormOpen ? (
					<div onClick={closeButtonHandler} className={styles.BuyNowBtn}>
						<MdSupportAgent size={30} color='white' />
					</div>
				) : (
					<div onClick={closeButtonHandler} className={styles.CloseButton}>
						<IoCloseSharp size={30} color='white' />
					</div>
				)}
			</div>
			<div className={`${styles.stickyFormBackround}  ${isFormOpen ? 'd-block' : 'd-none'}`} onClick={() => setIsFormOpen(false)}></div>
		</>
	)
}

export default StickyForm
