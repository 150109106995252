'use client'

import React from 'react'
import { BsWhatsapp } from 'react-icons/bs'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { ReactGAEvent } from '@/components/ReactGAs'


const WatiScript = () => {
	const pathname = usePathname()

	const onClickHnadler = () => {
		ReactGAEvent({ action: 'Send Whats App', event: 'Whats_app_click', label: 'Whats app click', category: 'chatbot' })
	}

	// const onLoadFunc = () => {
	// 	const options = {
	// 		enabled: true,
	// 		chatButtonSetting: {
	// 			backgroundColor: '#00e785',
	// 			ctaText: 'Chat with us',
	// 			borderRadius: '25',
	// 			marginLeft: '0',
	// 			marginRight: '20',
	// 			marginBottom: '20',
	// 			ctaIconWATI: false,
	// 			position: 'right',
	// 		},
	// 		brandSetting: {
	// 			brandName: 'Metalbook',
	// 			brandSubTitle: 'undefined',
	// 			brandImg: 'https://www.metalbook.co.in/_next/static/media/footer-mb.0bce3c2f.png',
	// 			welcomeText: 'Hi there!\nHow can I help you? ',
	// 			messageText: "Hello, I'm interested in Procurement and Customisation services from Metalbook, please help?",
	// 			backgroundColor: '#fff',
	// 			ctaText: 'Chat with us',
	// 			borderRadius: '25',
	// 			autoShow: false,
	// 			phoneNumber: '919205909152',
	// 		},
	// 	}

	// 	CreateWhatsappChatWidget(options)
	// }

	return (
		<>
			<div className={`wati-sticky-icon cursor-pointer ${pathname.includes('products') ? 'invisible' : ''}`}>
				<Link href='https://api.whatsapp.com/send?phone=919205909152&text=Hi' target='_blank' onClick={onClickHnadler}>
					<span>
						<BsWhatsapp />
					</span>
				</Link>
			</div>
		</>
	)
}

export default WatiScript
