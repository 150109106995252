"use client";

import { cookieKeys } from "@/constants";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import Providers from "./ProgressBarProvider";

const GlobalComponents = ({ children }) => {
  useEffect(() => {
    const cookies = new Cookies();
    let lang = cookies.get(cookieKeys.language);
    if (!lang) {
      lang = "en";
      cookies.set(cookieKeys.language, lang, { path: "/" });
    }
  }, []);
  return <Providers>{children}</Providers>;
};

export default GlobalComponents;
