export const cookieKeys = {
	user: 'mb-user',
	language: 'mb-language',
}

export const languages = {
	english: 'en',
	hindi: 'hi',
	bengali: 'be',
}

export const category = [
	{ name: 'All', commodity_id: 0, slug: '' },
	{ name: 'Mild Steel', commodity_id: 'COtx2Px-1001', slug: 'mild-steel' },
	{ name: 'Stainless Steel', commodity_id: 'CObxfTX-6615', slug: 'stainless-steel' },
	{ name: 'Aluminium', commodity_id: 'COtx2Sx-1131', slug: 'aluminium' },
	{ name: 'Copper', commodity_id: 'COLRzke-3499', slug: 'copper' },
	{ name: 'Zinc', commodity_id: 'COLRzke-3791', slug: 'zinc' },
	{ name: 'Nickel', commodity_id: 'COSFCRk-1464', slug: 'nickel' },
	{ name: 'Ferrous Scrap', commodity_id: 'COcArWq-5141', slug: 'ferrous-scrap' },
	{ name: 'Non Ferrous Scrap', commodity_id: 'COdjADD-7166', slug: 'non-ferrous-scrap' },
]

export const setHeader = { method: 'GET', headers: { 'mb-app-api': true } }
