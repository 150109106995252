import axios from 'axios'
const apiSizeCount = 1000

const AXIOS_OBJECT = (headers) => {
	let url = process.env.serverUrl

	if (
		(typeof window !== 'undefined' && window.location.href.includes('develop.d2zn1oavqk7hpv.amplifyapp.com')) ||
		process.env.REACT_APP_SERVER === 'local' ||
		process.env.REACT_APP_SERVER === 'develop'
	) {
		url = process.env.REACT_APP_DEV_URL
	}

	//   if (process.env.REACT_APP_SERVER === "local") {
	//     url = process.env.REACT_APP_LOCAL_URL;
	//   }

	return axios.create({
		baseURL: url,
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Headers': 'POST,GET,OPTIONS,PUT,DELETE',
			'Access-Control-Allow-Methods': 'Content-Type,*',
			'Access-Control-Allow-Credentials': true,
			'mb-app-api': true,
			authorization: headers?.authToken && 'Bearer ' + headers?.authToken,
			'mb-auth-uid': headers?.uid,
			'mb-id': headers?.user_id,
			'mb-org-id': headers?.organisation_id,
			'mb-url': typeof window !== 'undefined' ? window?.location?.href : null,
		},
	})
}

const callMethod = (method, api, data, params = {}, customHeaders = {}) =>
	new Promise((resolve, reject) => {
		const headers = {}

		let methodToCall = null

		if (method === 'post') {
			methodToCall = AXIOS_OBJECT(headers).post(api, data, {
				params,
				...customHeaders,
			})
		} else if (method === 'get') {
			if (!data.removeDefault) {
				data['size'] = data.size || apiSizeCount
			}
			data.removeDefault = null
			methodToCall = AXIOS_OBJECT(headers).get(api, {
				params: data,
				...params,
			})
		}

		if (methodToCall === null) reject('contact support')

		methodToCall
			.then((response) => {
				if (response.error) {
					if (response.authenticated === false) {
						reject('authenticated failed')
					} else {
						reject(response.error)
					}
				} else {
					resolve(response)
				}
			})
			.catch((err) => {
				reject(err)
			})
	})

const API = {
	get: (api, data = {}, options) => callMethod('get', api, data, options),
	post: (api, data, params, customHeaders) => callMethod('post', api, data, params, customHeaders),
}

export default API
