import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import styles from '../../page.module.scss'

const Input = ({
	id,
	name = null,
	label = null,
	labelIcon = null,
	uppercase,
	disabled,
	errors = null,
	cssClasses = '',
	rules,
	control,
	value,
	...props
}) => {
	return (
		<>
			<Form.Group>
				{label && (
					<Form.Label className='speck-form-label' htmlFor={id}>
						{label}
					</Form.Label>
				)}
				<InputGroup className='flex-nowrap'>
					{labelIcon && <InputGroup.Text>{labelIcon}</InputGroup.Text>}
					<div className='w-100'>
						<Controller
							name={name}
							rules={rules}
							control={control}
							render={({ field }) => (
								<div className='w-100'>
									<Form.Control
										id={id}
										uppercase={uppercase}
										disabled={disabled}
										className={`${styles.speakInput} ${cssClasses}`}
										autoComplete='off'
										value={value || ''}
										{...field}
										{...props}
									/>
									{errors && <Form.Text className={`${styles.spanError} text-danger card-span`}>{errors}</Form.Text>}
								</div>
							)}
						/>
					</div>
				</InputGroup>
			</Form.Group>
		</>
	)
}

export default Input
