'use client'

import { useEffect, useState } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import Link from 'next/link'
import Image from 'next/image'
import { Col, Row, Container, Button, Nav, Navbar, NavDropdown, Offcanvas } from 'react-bootstrap'

import MenuOne from '@/assets/menu/newspaper.webp'
import MenuTwo from '@/assets/menu/price-tag.webp'
import ArrowIcon from '@/assets/menu/arrow-icon.svg'
import ArrowIconActive from '@/assets/menu/arrow-icon-active.svg'
import useScrollListener from '@/hooks/useScrollListener'
import { LuFactory, LuRecycle, LuTruck, LuShoppingCart } from 'react-icons/lu'
import { GiTakeMyMoney } from 'react-icons/gi'
import { MdOutlineShoppingCartCheckout } from 'react-icons/md'
import { ReactGAEvent } from '@/components/ReactGAs'

const routeName = [
	{ name: 'Buying', url: '/buying', icon: <LuShoppingCart /> },
	{ name: 'Selling', url: '/selling', icon: <MdOutlineShoppingCartCheckout /> },
	{ name: 'Metal Fabrication', url: '/metal-fabrication', icon: <LuFactory /> },
	{ name: 'Finance', url: '/finance', icon: <GiTakeMyMoney /> },
	{ name: 'Recycling', url: '/recycling', icon: <LuRecycle /> },
	{ name: 'Logistics', url: '/logistics', icon: <LuTruck /> },
]

function Header() {
	const pathName = usePathname()
	const router = useRouter()
	const [navClassList, setNavClassList] = useState([])
	const scroll = useScrollListener()

	const [showOffcanvas, setShowOffcanvas] = useState(false)

	const handleClose = () => setShowOffcanvas(false)
	const handleShow = () => setShowOffcanvas(true)

	const handleCell = (name, url = null) => {
		ReactGAEvent({ action: `${name} Nav Click`, event: 'view_page', label: 'Blank', category: name })
		url && router.push(url)
		handleClose()
	}

	const logoEventHandler = () => {
		ReactGAEvent({ action: 'Logo click', event: 'view_page', label: 'Blank', category: 'Header' })
	}

	const signInHandler = () => {
		ReactGAEvent({ action: 'Home Page View', event: 'Initiate Login', label: 'Login as buyer or supplier', category: 'Header' })
	}

	const bestFeaturesHandler = (name) => {
		handleCell(name)
	}

	useEffect(() => {
		if (!window?.location?.hash.includes('#')) window.scroll(0, 0)
	}, [pathName])

	useEffect(() => {
		let _classList = []
		if (scroll.y > 150) {
			if (scroll.y - scroll.lastY > 0) {
				_classList.push('nav-bar--hidden')
				setNavClassList(_classList)
			} else if (_classList && scroll.y - scroll.lastY < -50) {
				_classList = []
				setNavClassList(_classList)
			}
		} else {
			setNavClassList(_classList)
		}
	}, [scroll.y, scroll.lastY])

	const checkServices = () => {
		return routeName?.find((item) => item?.url === pathName)
	}

	return (
		<Navbar id='navbar' expand={'md'} className={`${navClassList.join(' ')}`}>
			<Container className='header-wapper'>
				<Link className='d-flex align-items-center gap-2 header-logo' href='/' onClick={logoEventHandler}>
					<img src='/logo.webp' alt='metalbook' />
					<h2 className='logo-text'>
						<b>METAL</b>
						<span>BOOK</span>
					</h2>
				</Link>
				<Navbar.Toggle
					aria-controls={`offcanvasNavbar-expand-${'md'}`}
					onClick={() => {
						handleShow()
					}}
					className='border-0'
				/>
				<Navbar.Offcanvas
					id={`offcanvasNavbar-expand-${'md'}`}
					aria-labelledby={`offcanvasNavbarLabel-expand-${'md'}`}
					placement='end'
					show={showOffcanvas}
					onHide={handleClose}
				>
					<Offcanvas.Header closeButton>
						<Link
							className='d-flex align-items-center gap-2 header-logo'
							href='/'
							onClick={() => {
								handleClose()
								logoEventHandler
							}}
						>
							<img src='/logo.webp' alt='metalbook' />
							<h2 className='logo-text'>
								<b>METAL</b>
								<span>BOOK</span>
							</h2>
						</Link>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Nav className='offcanvas-body-nav flex-grow-1 pe-3'>
							<Link
								href='/'
								className={`${pathName == '/' ? 'active' : 'text-black-50'} nav-link-hover home-mobile-view`}
								onClick={() => {
									handleClose()
								}}
							>
								Home
							</Link>
							<NavDropdown
								title={<span className={`${checkServices() && 'text-dark'} nav-link-hover`}>Services</span>}
								id={`offcanvasNavbarDropdown-expand-${'md'}`}
							>
								<Row className='d-flex header-container gx-0 shadow'>
									<Col md={5} className='row-header-left'>
										<div>
											<p className='header-title'>Core Services</p>
											<div className='mt-2'>
												{routeName?.map((item, index) => (
													<NavDropdown.Item key={`${index}-${item?.url}`} onClick={() => handleCell(item?.name, item?.url)}>
														<Link
															href={item?.url}
															className={`${pathName == item?.url ? 'active' : 'text-black-50'} nav-link-hover`}
															onClick={() => {
																handleClose()
															}}
														>
															{item.icon}
															{item?.name}
														</Link>
													</NavDropdown.Item>
												))}
											</div>
										</div>
									</Col>
									<Col md={7} className='d-flex'>
										<div className='row-header-right'>
											<p className='header-title'>Best Features</p>
											<NavDropdown.Item>
												<Link
													href='/blogs#news'
													onClick={() => {
														bestFeaturesHandler('News')
														handleClose()
													}}
													className='d-flex'
												>
													<div className='d-flex align-items-center'>
														<Image src={MenuOne} alt='..' />
														<div className='flex-1'>
															<p>News</p>
														</div>
														<Image src={ArrowIconActive} alt='..' />
													</div>
												</Link>
											</NavDropdown.Item>
											<NavDropdown.Item>
												<Link
													href='/commodity-prices'
													onClick={() => {
														bestFeaturesHandler('Prices')
														handleClose()
													}}
													className='d-flex'
												>
													<div className='d-flex align-items-center'>
														<Image src={MenuTwo} alt='..' />
														<div className='flex-1'>
															<p>Prices </p>
														</div>
														<Image src={ArrowIcon} alt='..' />
													</div>
												</Link>
											</NavDropdown.Item>
										</div>
									</Col>
								</Row>
							</NavDropdown>
							<Link
								href='/products'
								className={`${pathName == '/products' ? 'active' : 'text-black-50'} nav-link-hover`}
								onClick={() => {
									bestFeaturesHandler('Products')
									handleClose()
								}}
							>
								Products
							</Link>
							<Link
								href='/about-us'
								className={`${pathName == '/about-us' ? 'active' : 'text-black-50'} nav-link-hover`}
								onClick={() => {
									bestFeaturesHandler('About Us')
									handleClose()
								}}
							>
								About Us
							</Link>
							<Link
								href='/blogs'
								className={`${pathName == '/blogs' ? 'active' : 'text-black-50'} nav-link-hover`}
								onClick={() => {
									bestFeaturesHandler('Blogs')
									handleClose()
								}}
							>
								Blogs
							</Link>
							<Nav.Link href='https://metalbook.app' target='_blank' className='sign-button d-none d-sm-block' onClick={signInHandler}>
								<Button className='border-0'>Sign In</Button>
							</Nav.Link>
						</Nav>
						<div className='d-block d-sm-none nav-link-button'>
							<Nav.Link href='https://calendly.com/metalbook/metalbook-live-demo' target='_blank' className='sign-button'>
								<button className='border-0 btn btn-secondary'>Schedule a demo</button>
							</Nav.Link>
							<Nav.Link href='https://metalbook.app' target='_blank' className='sign-button' onClick={signInHandler}>
								<Button className='border-0'>Sign In</Button>
							</Nav.Link>
						</div>
					</Offcanvas.Body>
				</Navbar.Offcanvas>
			</Container>
		</Navbar>
	)
}

export default Header
